export enum ACTION_TYPE {
  CREATE_TASK = "CREATE_TASK",
  DELETE_TASK = "DELETE_TASK",
  MOVE_BACK_TASK = "MOVE_BACK_TASK",
  MOVE_FORWARD_TASK = "MOVE_FORWARD_TASK",
  SELECT_TASK = "SELECT_TASK",
  SAVE_USER_INFO = "SAVE_USER_INFO",
  LOGOUT = "LOGOUT",
  FETCH_START = "FETCH_START",
  FETCH_SUCCESS = "FETCH_SUCCESS",
  FETCH_FAIL = "FETCH_FAIL",
  ADD_MESSAGE = "ADD_MESSAGE",
  INITIAL_MESSAGE = "INITIAL_MESSAGE",
  SET_LAYOUT = "SET_LAYOUT",
}

export const EMPTY_FN = () => {
  return;
};
